
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    category: {
      type: Object,
      default: null,
    },
  },

  async mounted() {
    await this.$nextTick()

    this.initAnimation()
  },

  methods: {
    initAnimation() {
      const q = this.$gsap.utils.selector(this.$el)
      const breakpoint = 1024
      const mm = this.$gsap.matchMedia()

      // mobile
      mm.add(`(max-width: ${breakpoint}px)`, () => {
        this.$gsap
          .timeline({ onComplete: () => this.$nuxt.$emit('hero-animated') })
          .to(q('.part-right'), {
            duration: 0.8,
            delay: 0.3,
            opacity: 1,
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          })
          .to(
            q('.part-left .slide-up-subtitle'),
            {
              opacity: 1,
              yPercent: -100,
              stagger: 0.2,
            },
            '<30%'
          )
          .to(
            q('.part-left .slide-up'),
            {
              opacity: 1,
              y: 0,
              stagger: 0.2,
            },
            '<30%'
          )
      })

      // desktop
      mm.add(`(min-width: ${breakpoint + 1}px)`, () => {
        this.$gsap
          .timeline({ onComplete: () => this.$nuxt.$emit('hero-animated') })
          .to(q('.part-left'), {
            delay: 0.3,
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          })
          .to(
            q('.part-left .slide-up'),
            {
              opacity: 1,
              y: 0,
              stagger: 0.15,
            },
            '<30%'
          )
          .to(
            q('.part-left .slide-up-subtitle'),
            {
              opacity: 1,
              yPercent: 0,
            },
            '<'
          )
          .to(
            q('.part-right'),
            {
              opacity: 1,
            },
            '<50%'
          )
      })
    },
  },
}
